<template>
  <div id="rankGame" class="original">
    <div class="rankGame max-width">
      <div class="rankGame_title">游戏排行</div>
      <div class="rankGame_content">
        <div class="nav_box">
          <div class="nav_item" v-for="(item, index) in rankgameList" :key="index" :class="{ active: index == indexs }"
            @click="activateNav(index)">
            <div class="nav_img">
              <img :src="require(index == indexs
                ? '../../assets/image/icon/61.png'
                : '../../assets/image/icon/60.png')
                " alt="" />
            </div>
            <div class="title">{{ item.title }}</div>
          </div>
        </div>
        <!-- 内容 -->
        <div class="swiper rank_swiper content_box">
          <div class="swiper-wrapper">
            <div class="swiper swiper-slide stop-swiping swiper_box" v-for="(item, index) in rankgameList" :key="index"
              :class="`each_swiper${index}`">
              <!-- 对应导航模块下面的list -->
              <div class="swiper-wrapper">
                <div class="swiper-slide content_box1" v-for="(each, eachIndex) in item.content" :key="eachIndex">
                  <!-- contetn下面的每一个对象 -->
                  <div v-for="(present, presentIndex) in each" :key="presentIndex" class="content_item"
                    @click="download(present.gw2)">
                    <div class="content_img">
                      <a target="_blank">
                        <img :src="present.url" alt="" />
                      </a>
                    </div>
                    <div class="head">
                      <p class="title">{{ present.head }}</p>
                      <p class="tj">{{ present.tj }}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="swiper-button-next"></div>
              <div class="swiper-button-prev"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { downloadA } from "@/utils/download";
export default {
  data() {
    return {
      indexs: 0,
      isShow: false,
      rankgameList: [
        {
          title: "推荐榜",
          show: true,
          content: [
            [
              {
                show: true,
                url: require("@/assets/image/pc/rankGame/jsmj.png"),
                head: "绝世秘籍",
                tj: "推荐指数:",
                gw2: "",
              },
              {
                show: true,
                url: require("@/assets/image/pc/rankGame/mhqn.jpg"),
                head: "梦幻千年",
                tj: "推荐指数:",
                gw2: "",
              },
              {
                show: true,
                url: require("@/assets/image/pc/rankGame/bxqn.png"),
                head: "冰雪千年",
                tj: "推荐指数:",
                gw2: "",
              },
              {
                show: true,
                url: require("@/assets/image/pc/rankGame/jxq.png"),
                head: "剑侠情",
                tj: "推荐指数:",
                gw2: "",
              },
              {
                show: true,
                url: require("@/assets/image/pc/rankGame/qxz.png"),
                head: "群侠传",
                tj: "推荐指数:",
                gw2: "",
              },
              {
                show: true,
                url: require("@/assets/image/pc/rankGame/wdqn.png"),
                head: "武动千年",
                tj: "推荐指数:",
                gw2: "",
              },

            ],
            [
              {
                show: true,
                url: require("@/assets/image/pc/rankGame/rx.png"),
                head: "热血Online",
                tj: "推荐指数:",
                gw2: "",
              },
              {
                show: true,
                url: require("@/assets/image/pc/rankGame/jyss.png"),
                head: "九妖盛世版",
                tj: "推荐指数:",
                gw2: "",
              },
              {
                show: true,
                url: require("@/assets/image/pc/rankGame/ltzj.png"),
                head: "戮天之剑",
                tj: "推荐指数：",
                gw2: "",
              },
              {
                show: true,
                url: require("@/assets/image/pc/rankGame/jyqn.png"),
                head: "九妖千年",
                tj: "推荐指数：",
                gw2: "",
              },
              {
                show: true,
                url: require("@/assets/image/pc/rankGame/szsd.png"),
                head: "神州三端",
                tj: "推荐指数:",
                gw2: "",
              },
              {
                show: true,
                url: require("@/assets/image/pc/rankGame/yyjh.png"),
                head: "烟雨江湖",
                tj: "推荐指数:",
                gw2: "",
              },
            ],
            [
              {
                show: true,
                url: require("@/assets/image/pc/rankGame/jjgc.png"),
                head: "精绝古城",
                tj: "推荐指数:",
                gw2: "",
              },
              {
                show: true,
                url: require("@/assets/image/pc/rankGame/wjjh.png"),
                head: "问剑江湖",
                tj: "推荐指数:",
                gw2: "",
              },
              {
                show: true,
                url: require("@/assets/image/pc/rankGame/xss.png"),
                head: "新盛世",
                tj: "推荐指数:",
                gw2: "",
              },
              {
                show: true,
                url: require("@/assets/image/pc/newGame/2.jpg"),
                head: "江湖",
                tj: "推荐指数:",
                gw2: "",
              },
              {
                show: true,
                url: require("@/assets/image/pc/newGame/3.jpg"),
                head: "灵武世界",
                tj: "推荐指数:",
                gw2: "",
              },
              {
                show: true,
                url: require("@/assets/image/pc/rankGame/dxts.png"),
                head: "大侠坛说",
                tj: "推荐指数:",
                gw2: "",
              },
            ],
            [
              {
                show: true,
                url: require("@/assets/image/pc/rankGame/xkxqn.png"),
                head: "侠客行千年",
                tj: "推荐指数:",
                gw2: "",
              },
              {
                show: true,
                url: require("@/assets/image/pc/rankGame/zzbj.png"),
                head: "诸子百家之千年武侠",
                tj: "推荐指数:",
                gw2: "",
              },
              {
                show: true,
                url: require("@/assets/image/pc/newGame/jj.jpg"),
                head: "九剑魔龙传",
                tj: "推荐指数:",
                gw2: "",
              },
              {
                show: true,
                url: require("@/assets/image/pc/newGame/jc.jpg"),
                head: "千年盛世之极纯千年",
                tj: "推荐指数:",
                gw2: "",
              },
              {
                show: true,
                url: require("@/assets/image/pc/newGame/fg.jpg"),
                head: "复古千年",
                tj: "推荐指数:",
                gw2: "",
              },
              {
                show: true,
                url: require("@/assets/image/pc/newGame/fs.jpg"),
                head: "封神千年",
                tj: "推荐指数:",
                gw2: "",
              },
            ],
            [
              {
                show: true,
                url: require("@/assets/image/pc/rankGame/fyzq.jpg"),
                head: "风云再起",
                tj: "推荐指数:",
                gw2: "",
              },
              {
                show: true,
                url: require("@/assets/image/pc/rankGame/jjmlz.jpg"),
                head: "九剑魔龙传",
                tj: "推荐指数:",
                gw2: "",
              },
              {
                show: true,
                url: require("@/assets/image/pc/rankGame/jyswb.jpg"),
                head: "九妖神武版",
                tj: "推荐指数:",
                gw2: "",
              },
              {
                show: true,
                url: require("@/assets/image/pc/rankGame/jxqn.png"),
                head: "觉醒千年",
                tj: "推荐指数:",
                gw2: "",
              },
              {
                show: true,
                url: require("@/assets/image/pc/rankGame/jzb.jpg"),
                head: "千年盛世之激战版",
                tj: "推荐指数:",
                gw2: "",
              },
              {
                show: true,
                url: require("@/assets/image/pc/rankGame/mfb.jpg"),
                head: "千年盛世之免费版",
                tj: "推荐指数:",
                gw2: "",
              },
            ],
            [
              {
                show: true,
                url: require("@/assets/image/pc/rankGame/sstx.jpg"),
                head: "盛世天下",
                tj: "推荐指数:",
                gw2: "",
              },
              {
                show: true,
                url: require("@/assets/image/pc/rankGame/ccyn.jpg"),
                head: "长城以南OL",
                tj: "推荐指数:",
                gw2: "",
              },
              {
                show: true,
                url: require("@/assets/image/pc/rankGame/smjs.png"),
                head: "水墨江山",
                tj: "推荐指数:",
                gw2: "",
              },
              {
                show: true,
                url: require("@/assets/image/pc/rankGame/jyrg.jpg"),
                head: "剑雨如歌",
                tj: "推荐指数:",
                gw2: "",
              },
              {
                show: true,
                url: require("@/assets/image/pc/rankGame/xzd.jpg"),
                head: "侠众道",
                tj: "推荐指数:",
                gw2: "",
              },
              {
                show: true,
                url: require("@/assets/image/pc/rankGame/lhcfjz.png"),
                head: "离火重返激战",
                tj: "推荐指数:",
                gw2: "",
              },

            ],
            [
              {
                show: true,
                url: require("@/assets/image/pc/rankGame/qnwc.png"),
                head: "千年王朝",
                tj: "推荐指数:",
                gw2: "",
              },
            ],
          ],
        },
        {
          title: "新游榜",
          show: false,
          // contetn: "",
          content: [
            [
              {
                show: true,
                url: require("@/assets/image/pc/rankGame/jsmj.png"),
                head: "绝世秘籍",
                tj: "推荐指数:",
                gw2: "",
              },
              {
                show: true,
                url: require("@/assets/image/pc/rankGame/mhqn.jpg"),
                head: "梦幻千年",
                tj: "推荐指数:",
                gw2: "",
              },
              {
                show: true,
                url: require("@/assets/image/pc/rankGame/bxqn.png"),
                head: "冰雪千年",
                tj: "推荐指数:",
                gw2: "",
              },
              {
                show: true,
                url: require("@/assets/image/pc/rankGame/jxq.png"),
                head: "剑侠情",
                tj: "推荐指数:",
                gw2: "",
              },
              {
                show: true,
                url: require("@/assets/image/pc/rankGame/qxz.png"),
                head: "群侠传",
                tj: "推荐指数:",
                gw2: "",
              },
              {
                show: true,
                url: require("@/assets/image/pc/rankGame/wdqn.png"),
                head: "武动千年",
                tj: "推荐指数:",
                gw2: "",
              },

            ],
            [
              {
                show: true,
                url: require("@/assets/image/pc/rankGame/rx.png"),
                head: "热血Online",
                tj: "推荐指数:",
                gw2: "",
              },
              {
                show: true,
                url: require("@/assets/image/pc/rankGame/jyss.png"),
                head: "九妖盛世版",
                tj: "推荐指数:",
                gw2: "",
              },
              {
                show: true,
                url: require("@/assets/image/pc/rankGame/ltzj.png"),
                head: "戮天之剑",
                tj: "推荐指数：",
                gw2: "",
              },
              {
                show: true,
                url: require("@/assets/image/pc/rankGame/jyqn.png"),
                head: "九妖千年",
                tj: "推荐指数：",
                gw2: "",
              },
              {
                show: true,
                url: require("@/assets/image/pc/rankGame/szsd.png"),
                head: "神州三端",
                tj: "推荐指数:",
                gw2: "",
              },
              {
                show: true,
                url: require("@/assets/image/pc/rankGame/yyjh.png"),
                head: "烟雨江湖",
                tj: "推荐指数:",
                gw2: "",
              },
            ],
            [
              {
                show: true,
                url: require("@/assets/image/pc/rankGame/jjgc.png"),
                head: "精绝古城",
                tj: "推荐指数:",
                gw2: "",
              },
              {
                show: true,
                url: require("@/assets/image/pc/rankGame/wjjh.png"),
                head: "问剑江湖",
                tj: "推荐指数:",
                gw2: "",
              },
              {
                show: true,
                url: require("@/assets/image/pc/rankGame/xss.png"),
                head: "新盛世",
                tj: "推荐指数:",
                gw2: "",
              },
              {
                show: true,
                url: require("@/assets/image/pc/newGame/2.jpg"),
                head: "江湖",
                tj: "推荐指数:",
                gw2: "",
              },
              {
                show: true,
                url: require("@/assets/image/pc/newGame/3.jpg"),
                head: "灵武世界",
                tj: "推荐指数:",
                gw2: "",
              },
              {
                show: true,
                url: require("@/assets/image/pc/rankGame/dxts.png"),
                head: "大侠坛说",
                tj: "推荐指数:",
                gw2: "",
              },
            ],
            [
              {
                show: true,
                url: require("@/assets/image/pc/rankGame/xkxqn.png"),
                head: "侠客行千年",
                tj: "推荐指数:",
                gw2: "",
              },
              {
                show: true,
                url: require("@/assets/image/pc/rankGame/zzbj.png"),
                head: "诸子百家之千年武侠",
                tj: "推荐指数:",
                gw2: "",
              },
              {
                show: true,
                url: require("@/assets/image/pc/newGame/jj.jpg"),
                head: "九剑魔龙传",
                tj: "推荐指数:",
                gw2: "",
              },
              {
                show: true,
                url: require("@/assets/image/pc/newGame/jc.jpg"),
                head: "千年盛世之极纯千年",
                tj: "推荐指数:",
                gw2: "",
              },
              {
                show: true,
                url: require("@/assets/image/pc/newGame/fg.jpg"),
                head: "复古千年",
                tj: "推荐指数:",
                gw2: "",
              },
              {
                show: true,
                url: require("@/assets/image/pc/newGame/fs.jpg"),
                head: "封神千年",
                tj: "推荐指数:",
                gw2: "",
              },
            ],
            [
              {
                show: true,
                url: require("@/assets/image/pc/rankGame/fyzq.jpg"),
                head: "风云再起",
                tj: "推荐指数:",
                gw2: "",
              },
              {
                show: true,
                url: require("@/assets/image/pc/rankGame/jjmlz.jpg"),
                head: "九剑魔龙传",
                tj: "推荐指数:",
                gw2: "",
              },
              {
                show: true,
                url: require("@/assets/image/pc/rankGame/jyswb.jpg"),
                head: "九妖神武版",
                tj: "推荐指数:",
                gw2: "",
              },
              {
                show: true,
                url: require("@/assets/image/pc/rankGame/jxqn.png"),
                head: "觉醒千年",
                tj: "推荐指数:",
                gw2: "",
              },
              {
                show: true,
                url: require("@/assets/image/pc/rankGame/jzb.jpg"),
                head: "千年盛世之激战版",
                tj: "推荐指数:",
                gw2: "",
              },
              {
                show: true,
                url: require("@/assets/image/pc/rankGame/mfb.jpg"),
                head: "千年盛世之免费版",
                tj: "推荐指数:",
                gw2: "",
              },
            ],
            [
              {
                show: true,
                url: require("@/assets/image/pc/rankGame/sstx.jpg"),
                head: "盛世天下",
                tj: "推荐指数:",
                gw2: "",
              },
              {
                show: true,
                url: require("@/assets/image/pc/rankGame/ccyn.jpg"),
                head: "长城以南OL",
                tj: "推荐指数:",
                gw2: "",
              },
              {
                show: true,
                url: require("@/assets/image/pc/rankGame/smjs.png"),
                head: "水墨江山",
                tj: "推荐指数:",
                gw2: "",
              },
              {
                show: true,
                url: require("@/assets/image/pc/rankGame/jyrg.jpg"),
                head: "剑雨如歌",
                tj: "推荐指数:",
                gw2: "",
              },
              {
                show: true,
                url: require("@/assets/image/pc/rankGame/xzd.jpg"),
                head: "侠众道",
                tj: "推荐指数:",
                gw2: "",
              },
              {
                show: true,
                url: require("@/assets/image/pc/rankGame/lhcfjz.png"),
                head: "离火重返激战",
                tj: "推荐指数:",
                gw2: "",
              },

            ],
            [
              {
                show: true,
                url: require("@/assets/image/pc/rankGame/qnwc.png"),
                head: "千年王朝",
                tj: "推荐指数:",
                gw2: "",
              },
            ],
          ],
        },
        {
          title: "人气榜",
          show: false,
          content: [
            [
              {
                show: true,
                url: require("@/assets/image/pc/rankGame/jsmj.png"),
                head: "绝世秘籍",
                tj: "推荐指数:",
                gw2: "",
              },
              {
                show: true,
                url: require("@/assets/image/pc/rankGame/mhqn.jpg"),
                head: "梦幻千年",
                tj: "推荐指数:",
                gw2: "",
              },
              {
                show: true,
                url: require("@/assets/image/pc/rankGame/bxqn.png"),
                head: "冰雪千年",
                tj: "推荐指数:",
                gw2: "",
              },
              {
                show: true,
                url: require("@/assets/image/pc/rankGame/jxq.png"),
                head: "剑侠情",
                tj: "推荐指数:",
                gw2: "",
              },
              {
                show: true,
                url: require("@/assets/image/pc/rankGame/qxz.png"),
                head: "群侠传",
                tj: "推荐指数:",
                gw2: "",
              },
              {
                show: true,
                url: require("@/assets/image/pc/rankGame/wdqn.png"),
                head: "武动千年",
                tj: "推荐指数:",
                gw2: "",
              },

            ],
            [
              {
                show: true,
                url: require("@/assets/image/pc/rankGame/rx.png"),
                head: "热血Online",
                tj: "推荐指数:",
                gw2: "",
              },
              {
                show: true,
                url: require("@/assets/image/pc/rankGame/jyss.png"),
                head: "九妖盛世版",
                tj: "推荐指数:",
                gw2: "",
              },
              {
                show: true,
                url: require("@/assets/image/pc/rankGame/ltzj.png"),
                head: "戮天之剑",
                tj: "推荐指数：",
                gw2: "",
              },
              {
                show: true,
                url: require("@/assets/image/pc/rankGame/jyqn.png"),
                head: "九妖千年",
                tj: "推荐指数：",
                gw2: "",
              },
              {
                show: true,
                url: require("@/assets/image/pc/rankGame/szsd.png"),
                head: "神州三端",
                tj: "推荐指数:",
                gw2: "",
              },
              {
                show: true,
                url: require("@/assets/image/pc/rankGame/yyjh.png"),
                head: "烟雨江湖",
                tj: "推荐指数:",
                gw2: "",
              },
            ],
            [
              {
                show: true,
                url: require("@/assets/image/pc/rankGame/jjgc.png"),
                head: "精绝古城",
                tj: "推荐指数:",
                gw2: "",
              },
              {
                show: true,
                url: require("@/assets/image/pc/rankGame/wjjh.png"),
                head: "问剑江湖",
                tj: "推荐指数:",
                gw2: "",
              },
              {
                show: true,
                url: require("@/assets/image/pc/rankGame/xss.png"),
                head: "新盛世",
                tj: "推荐指数:",
                gw2: "",
              },
              {
                show: true,
                url: require("@/assets/image/pc/newGame/2.jpg"),
                head: "江湖",
                tj: "推荐指数:",
                gw2: "",
              },
              {
                show: true,
                url: require("@/assets/image/pc/newGame/3.jpg"),
                head: "灵武世界",
                tj: "推荐指数:",
                gw2: "",
              },
              {
                show: true,
                url: require("@/assets/image/pc/rankGame/dxts.png"),
                head: "大侠坛说",
                tj: "推荐指数:",
                gw2: "",
              },
            ],
            [
              {
                show: true,
                url: require("@/assets/image/pc/rankGame/xkxqn.png"),
                head: "侠客行千年",
                tj: "推荐指数:",
                gw2: "",
              },
              {
                show: true,
                url: require("@/assets/image/pc/rankGame/zzbj.png"),
                head: "诸子百家之千年武侠",
                tj: "推荐指数:",
                gw2: "",
              },
              {
                show: true,
                url: require("@/assets/image/pc/newGame/jj.jpg"),
                head: "九剑魔龙传",
                tj: "推荐指数:",
                gw2: "",
              },
              {
                show: true,
                url: require("@/assets/image/pc/newGame/jc.jpg"),
                head: "千年盛世之极纯千年",
                tj: "推荐指数:",
                gw2: "",
              },
              {
                show: true,
                url: require("@/assets/image/pc/newGame/fg.jpg"),
                head: "复古千年",
                tj: "推荐指数:",
                gw2: "",
              },
              {
                show: true,
                url: require("@/assets/image/pc/newGame/fs.jpg"),
                head: "封神千年",
                tj: "推荐指数:",
                gw2: "",
              },
            ],
            [
              {
                show: true,
                url: require("@/assets/image/pc/rankGame/fyzq.jpg"),
                head: "风云再起",
                tj: "推荐指数:",
                gw2: "",
              },
              {
                show: true,
                url: require("@/assets/image/pc/rankGame/jjmlz.jpg"),
                head: "九剑魔龙传",
                tj: "推荐指数:",
                gw2: "",
              },
              {
                show: true,
                url: require("@/assets/image/pc/rankGame/jyswb.jpg"),
                head: "九妖神武版",
                tj: "推荐指数:",
                gw2: "",
              },
              {
                show: true,
                url: require("@/assets/image/pc/rankGame/jxqn.png"),
                head: "觉醒千年",
                tj: "推荐指数:",
                gw2: "",
              },
              {
                show: true,
                url: require("@/assets/image/pc/rankGame/jzb.jpg"),
                head: "千年盛世之激战版",
                tj: "推荐指数:",
                gw2: "",
              },
              {
                show: true,
                url: require("@/assets/image/pc/rankGame/mfb.jpg"),
                head: "千年盛世之免费版",
                tj: "推荐指数:",
                gw2: "",
              },
            ],
            [
              {
                show: true,
                url: require("@/assets/image/pc/rankGame/sstx.jpg"),
                head: "盛世天下",
                tj: "推荐指数:",
                gw2: "",
              },
              {
                show: true,
                url: require("@/assets/image/pc/rankGame/ccyn.jpg"),
                head: "长城以南OL",
                tj: "推荐指数:",
                gw2: "",
              },
              {
                show: true,
                url: require("@/assets/image/pc/rankGame/smjs.png"),
                head: "水墨江山",
                tj: "推荐指数:",
                gw2: "",
              },
              {
                show: true,
                url: require("@/assets/image/pc/rankGame/jyrg.jpg"),
                head: "剑雨如歌",
                tj: "推荐指数:",
                gw2: "",
              },
              {
                show: true,
                url: require("@/assets/image/pc/rankGame/xzd.jpg"),
                head: "侠众道",
                tj: "推荐指数:",
                gw2: "",
              },
              {
                show: true,
                url: require("@/assets/image/pc/rankGame/lhcfjz.png"),
                head: "离火重返激战",
                tj: "推荐指数:",
                gw2: "",
              },

            ],
            [
              {
                show: true,
                url: require("@/assets/image/pc/rankGame/qnwc.png"),
                head: "千年王朝",
                tj: "推荐指数:",
                gw2: "",
              },
            ],
          ],
        },
      ],
      rankSwiperList: [],
      rankSwiper: null,
    };
  },
  created() { },
  mounted() {
    this.initSwiper();
  },
  methods: {
    download(url) {
      return
      downloadA(url)
    },
    initSwiper() {
      this.rankSwiper = new Swiper(".rank_swiper", {
        centeredSlides: true,
        // initialSlide: index,
        noSwiping: true,
        initialSlide: this.indexs,
        noSwipingClass: "stop-swiping",
        observer: true, //修改swiper自己或子元素时，自动初始化swiper，主要是这两行
        observeParents: true, //修改swiper的父元素时，自动初始化swiper
      });
      this.rankSwiperList = [];
      this.rankgameList.forEach((item, index) => {
        let eachSwiper = new Swiper(`.each_swiper${index}`, {
          centeredSlides: true,
          // pagination: {
          //   el: ".swiper-pagination",
          //   clickable: true,
          //   bulletClass: "my-bullet3",
          //   clickableClass: "my-pagination-clickable",
          // },

          navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
          },
          loop: true,
          observer: true, //修改swiper自己或子元素时，自动初始化swiper，主要是这两行
          observeParents: true, //修改swiper的父元素时，自动初始化swiper
          on: {
            touchEnd: function (swiper, event) {
              console.log("出发蓝");
            },
          },
        });
        this.rankSwiperList.push(eachSwiper);
      });
    },
    activateNav(index) {
      // console.log(index, "index");
      this.indexs = index;
      // this.isShow = true;
      this.rankgameList.forEach((item) => {
        item.show = false;
      });
      this.rankgameList[index].show = true;

      this.rankSwiper.slideToLoop(index, 200, false);
    },
  },
};
</script>
<style>
.rankSwiper .my-bullet+.my-bullet {
  margin-left: 0.0625rem;
}

.rankSwiper .my-bullet3 {
  display: inline-block;
  width: 0.046875rem
    /* 9/192 */
  ;
  height: 0.046875rem
    /* 9/192 */
  ;
  border-radius: 50%;
  background-color: #949494;
}

.rankSwiper .swiper-pagination-bullet-active {
  background-color: #cc5341 !important;
}

/* .rankSwiper .swiper-horizontal > .swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal,
.swiper-pagination-custom,
.swiper-pagination-fraction {
  bottom: 0.2rem;
  left: 0%;
} */
</style>
<style scoped lang="scss">
.max-width {
  max-width: 1920px;
  margin: 0 auto;
}

.original {
  height: 5.398958rem
    /* 979/192 */
  ;
  position: relative;
  z-index: 2;
  background-color: #201a0e;
}

.rank_swiper {
  // flex: 1;
  width: 100%;
  box-sizing: border-box;
  margin-right: 0.15rem;

  .content_box {
    width: 100%;
    height: 100%;
  }

  .swiper-slide {
    // padding-left: .333333rem /* 64/192 */;
  }
}

.rankGame {
  height: 100%;

  background: url(../../assets/image/pc/bg/144.jpg) no-repeat 50%;
  // background: pink;
  // background-size: cover;
  display: flex;
  align-items: center;
  flex-direction: column;

  .rankGame_title {
    // height: 0.9375rem /* 180/192 */;
    // width: 100%;
    // text-align: center;
    // line-height: 0.9375rem;
    color: #f8f3e9;
    font-weight: bold;
    font-size: 0.208333rem
      /* 40/192 */
    ;
    padding-top: 0.390625rem
      /* 75/192 */
    ;
    padding-bottom: 0.390625rem
      /* 75/192 */
    ;
  }

  .rankGame_content {
    height: 4.161458rem
      /* 799/192 */
    ;
    width: 7.328125rem
      /* 1215/192 */
    ;
    // background: #ebd6d3;
    // background: palevioletred;
    display: flex;
    align-items: center;
    flex-direction: column;

    .nav_box {
      width: 6.328125rem
        /* 1215/192 */
      ;
      height: 0.364583rem
        /* 70/192 */
      ;
      background: #f6f5f4;
      border-radius: 0.052083rem
        /* 10/192 */
      ;
      color: #393333;
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      position: relative;
      z-index: 1;
      margin-bottom: 0.25rem
        /* 48/192 */
      ;

      .nav_item {
        width: 2.109375rem
          /* 405/192 */
        ;
        height: 0.364583rem
          /* 70/192 */
        ;
        text-align: center;
        font-size: 0.15625rem
          /* 30/192 */
        ;
        font-weight: bold;
        display: flex;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
        background-image: url("../../assets/image/icon/60.png") no-repeat;
        background-size: contain;
        cursor: pointer;

        .nav_img {
          width: 0.125rem
            /* 24/192 */
          ;
          height: 0.166667rem
            /* 32/192 */
          ;
          margin-right: 12px;

          img {
            width: 100%;
            height: 100%;
          }
        }
      }

      .active {
        background: #cc5341;
        color: #f8f3e9;
        border-radius: 0.052083rem
          /* 10/192 */
        ;
      }
    }

    .content_box1 {
      width: 6.328125rem
        /* 1215/192 */
      ;
      display: flex;
      // align-items: center;
      // justify-content: center;
      flex-wrap: wrap;

      // margin-top: 0.25rem /* 48/192 */;
      // margin-left: .109375rem /* 21/192 */;
      .content_item {
        width: 1.880208rem
          /* 361/192 */
        ;
        height: 1.453125rem
          /* 279/192 */
        ;
        background: #ffffff;
        border-radius: 0.052083rem
          /* 10/192 */
        ;
        opacity: 1;
        // margin-bottom: 0.229167rem /* 44/192 */;
        margin-left: 0.35rem;
      }

      .content_img {
        width: 1.880208rem
          /* 361/192 */
        ;
        height: 1.171875rem
          /* 225/192 */
        ;
        border-radius: 0.052083rem
          /* 10/192 */
          0.052083rem
          /* 10/192 */
          0px 0px;

        img {
          width: 100%;
          height: 100%;
          border-radius: 0.052083rem
            /* 10/192 */
            0.052083rem
            /* 10/192 */
            0px 0px;
        }
      }

      .head {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 0.28125rem
          /* 54/192 */
        ;
        width: 1.880208rem
          /* 361/192 */
        ;
        box-sizing: border-box;
        padding: 0 0.104167rem
          /* 20/192 */
        ;
        text-align: bottom;

        .title {
          font-size: 0.125rem
            /* 24/192 */
          ;
          color: #000000;
        }

        .tj {
          padding-top: 6.2px;
          font-size: 0.083333rem
            /* 16/192 */
          ;
          color: #cc5341;
        }
      }

      .empty_img {
        height: 3.127953rem
          /* 581.367/192 */
        ;
        width: 6.328125rem
          /* 1215/192 */
        ;
        // margin-left: .68rem /* 50/192 */;
        // margin-top: -1rem;
        // margin: 0 auto;
        background: #f6f5f4;
        // margin-bottom: .104167rem /* 20/192 */;
        position: relative;

        img {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          width: 57%;
          height: 140%;
        }
      }
    }

    .content_box1>div:nth-child(1) {
      margin-left: 0.5rem;
      margin-bottom: 0.25rem
        /* 48/192 */
      ;
    }

    .content_box1>div:nth-child(2) {
      margin-bottom: 0.25rem
        /* 48/192 */
      ;
    }

    .content_box1>div:nth-child(3) {
      margin-bottom: 0.25rem
        /* 48/192 */
      ;
    }

    .content_box1>div:nth-child(4) {
      margin-left: 0.5rem;
    }
  }
}

.swiper-button-prev {}

.swiper-button-next:after,
.swiper-rtl .swiper-button-prev:after {
  content: none;
}

.swiper-button-prev:after,
.swiper-rtl .swiper-button-next:after {
  content: none;
}

.swiper-button-prev {
  width: 0.15625rem
    /* 30/192 */
  ;
  height: 0.229167rem
    /* 44/192 */
  ;
  background: url(../../assets/image/icon/pyz.png) no-repeat;
  background-size: contain;
}

.swiper-button-next {
  width: 0.15625rem
    /* 30/192 */
  ;
  height: 0.229167rem
    /* 44/192 */
  ;
  background: url(../../assets/image/icon/paiyb.png) no-repeat;
  background-size: contain;
}

.swiper-button-next:hover {
  width: 0.15625rem
    /* 30/192 */
  ;
  height: 0.229167rem
    /* 44/192 */
  ;
  background: url(../../assets/image/icon/pyou.png) no-repeat;
  background-size: contain;
}

.swiper-button-prev:hover {
  width: 0.15625rem
    /* 30/192 */
  ;
  height: 0.229167rem
    /* 44/192 */
  ;
  background: url(../../assets/image/icon/pzuo.png) no-repeat;
  background-size: contain;
}
</style>

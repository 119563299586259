<template>
  <div id="bannerView">
    <!-- banner -->
    <div class="swiper wap_index1_box">
      <div class="swiper-wrapper">
        <div class="swiper-slide">
          <div class="swiper_img">
            <a href="https://mp.weixin.qq.com/s/90jEvtl5JpBA1hiKfxMyiA" target="_blank">
              <img src="../../assets/image/pc/banner/7501334-o.jpg" alt="" />
            </a>
          </div>
        </div>
        <div class="swiper-slide">
          <div class="swiper_img">
            <a href="https://mp.weixin.qq.com/s/DIQ9AfH0505vfgO0qBQtOg" target="_blank">
              <img src="../../assets/image/pc/banner/jq.jpg" alt="" />
            </a>
          </div>
        </div>
        <div class="swiper-slide">
          <div class="swiper_img">
            <a href="https://mp.weixin.qq.com/s/abzYupDefKAkLi9oBlRZtw" target="_blank">
              <img src="../../assets/image/pc/banner/new9.jpg" alt="" />
            </a>
          </div>
        </div>
        <div class="swiper-slide">
          <div class="swiper_img">
            <a href="https://mp.weixin.qq.com/s/SfqiCnSMZ8mvNaXA3P6_Mw" target="_blank">
              <img src="../../assets/image/pc/banner/7501334.jpg" alt="" />
            </a>
          </div>
        </div>
        <div class="swiper-slide">
          <div class="swiper_img">
            <a href="https://mp.weixin.qq.com/s/l1F93_MoYNPWv4o-evaeJA" target="_blank">
              <img src="../../assets/image/wap/banner/banner11.jpg" alt="" />
            </a>
          </div>
        </div>
      </div>
      <div class="swiper-pagination"></div>
    </div>
    <!-- 下载 -->
    <div class="download_box">
      <div class="dl_box">
        <div class="ios_box">
          <div class="ios_img">
            <img src="../../assets/image/icon/16.png" alt="" />
          </div>
          <div class="ios_text" @click="iosTo()">
            <p class="ios_xz">下载ios版本</p>
            <!-- <p class="ios_jqqd">(敬请期待...)</p> -->
          </div>
        </div>
        <div class="az_box" @click="azTo()">
          <div class="az_img">
            <img src="../../assets/image/icon/17.png" alt="" />
          </div>
          <div class="az_text">下载Android版本</div>
        </div>
        <div class="az_box" @click="PCTo()">
          <div class="az_img">
            <img src="../../assets/image/icon/pc.png" alt="" />
          </div>
          <div class="az_text">下载电脑版本</div>
        </div>
      </div>
    </div>
    <div class="toast" v-if="isToast">即将震撼来袭 敬请期待</div>
    <div class="yindao"></div>
  </div>
</template>

<script>
import { downloadA } from "@/utils/download";
export default {
  data() {
    return {
      isToast: false,
    };
  },
  created() { },
  mounted() {
    // let _this = this;
    this.$nextTick(() => {
      this.homeSwiper();
    });
  },
  methods: {
    iosTo() {
      this.$emit("update", 0, true);
    },
    azTo() {
      this.$emit("update", 1, true);
    },
    PCTo() {
      downloadA(PC_URL, '_self')
      // this.isToast = true;
      // setTimeout(() => {
      //   this.isToast = false;
      // }, 2000);
    },
    homeSwiper() {
      this.mySwiperbox1 = new Swiper(".wap_index1_box", {
        autoplay: true, //可选选项，自动滑动
        observer: true, //修改swiper自己或子元素时，自动初始化swiper，主要是这两行
        observeParents: true, //修改swiper的父元素时，自动初始化swiper
        autoplay: {
          delay: 3000,
          stopOnLastSlide: false,
          disableOnInteraction: false,
        },
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
          clickableClass: "my-pagination-clickable",
        },
        // loop: true,
        centeredSlides: true,
      });
    },
  },
};
</script>
<style>
@import "../../assets/css/swiper-bundle.min.css";
</style>
<style scoped lang="scss">
#bannerView {
  height: 100%;
  display: flex;
  flex-direction: column;
  .swiper_img{
    width: 100%;
    height: 100%;
  }
  .swiper {
    width: 100%;
    height: 100%;
    flex: 1;
  }

  .banner_box {
    position: relative;
  }

  .swiper-slide {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  ::v-deep .swiper-pagination-bullet {
    background: white;
    opacity: 1;
  }

  ::v-deep .swiper-pagination-bullet-active {
    background: red;
    // width: .2rem;
  }
}

.toast {
  width: 6.125rem
    /* 50/16 */
  ;
  height: 1rem;
  line-height: 1rem;
  font-size: 0.34rem;
  background: rgba(0, 0, 0, 0.7);
  text-align: center;
  border-radius: 0.18rem;
  color: #ffffff;
  position: absolute;
  top: 35%;
  left: 50%;
  transform: translate(-50%, -50%);

  z-index: 99999;
}

//下载
.download_box {
  width: 100%;
  flex: 0.27;
  background: #000;
  display: flex;
  align-items: center;
  // justify-content: center;
  flex-direction: column;
  text-align: center;

  .dl_box {
    display: flex;
    width: 100%;
    margin-top: 0.8rem;
    align-items: center;
  }

  .ios_box {
    width: 100%;
    height: 1.28rem
      /* 48/37.5 */
    ;
    // background: #ececec;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .ios_img {
      width: 0.613333rem
        /* 23/37.5 */
      ;
      height: 0.746667rem
        /* 28/37.5 */
      ;
      margin-right: 0.266667rem
        /* 10/37.5 */
      ;
      margin-bottom: 0.15rem;

      img {
        display: block;
        width: 100%;
        height: 100%;
      }
    }

    .ios_text {
      display: flex;
      align-items: center;

      justify-content: center;

      .ios_xz {
        color: #fff;
        font-size: 0.37rem;
        /* 18/37.5 */
      }

      .ios_jqqd {
        color: #949494;
        font-size: 0.426667rem;
        /* 16/37.5 */
      }
    }
  }

  .az_box {
    width: 100%;
    // height: 0.395833rem /* 76/192 */;
    border-radius: 0.052083rem
      /* 10/192 */
    ;
    opacity: 1;
    display: flex;
    align-items: center;
    flex-direction: column;
    border-left: 0.02rem solid #ccc;

    .az_img {
      width: 0.666667rem
        /* 25/37.5 */
      ;
      height: 0.72rem
        /* 27/37.5 */
      ;

      margin-bottom: 0.15rem;

      img {
        display: block;
        width: 100%;
        height: 100%;
      }
    }

    .az_text {
      color: #fff;
      font-size: 0.37rem;
      /* 18/37.5 */
    }
  }
}
</style>

<template>
  <div id="downloadGame" class="original">
    <div class="downloadGame max-width">
      <div class="downloadGame_title">下载盒子</div>
      <div class="content">
        <div class="xz_content" @click="iosTo()">
          <div class="xz_img">
            <div class="xia_img">
              <img src="../../assets/image/icon/16.png" alt />
            </div>
          </div>
          <div class="btn">下载ios版本</div>
          <!-- <div class="tex">IOS用户下载</div> -->
        </div>
        <div class="xz_content" @click="azTo()">
          <div class="xz_img">
            <div class="xia_img">
              <img src="../../assets/image/icon/17.png" alt />
            </div>
          </div>
          <div class="btn">下载Android版本</div>
          <!-- <div class="tex">安卓用户下载</div> -->
        </div>
        <div class="xz_content" @click="PCTo">
          <div class="xz_img">
            <div class="xia_img" style="width: 0.63rem; height: 0.52rem">
              <img src="../../assets/image/icon/pc.png" alt />
            </div>
          </div>
          <div class="btn">下载电脑版本</div>
          <!-- <div class="tex">扫码下载</div> -->
        </div>
        <div class="xz_content">
          <div class="xz_img">
            <div class="xia_img" style="width: 0.677rem; height: 0.677rem">
              <img src="../../assets/image/icon/xz.jpg" alt="" />
            </div>
          </div>
          <div class="btn" style="width: 1.5rem">长按保存,扫码下载</div>
          <!-- <div class="tex">扫码下载</div> -->
        </div>
        <div class="xz_content">
          <div class="xz_img">
            <div class="xia_img" style="width: 0.677rem; height: 0.677rem">
              <img src="../../assets/image/icon/gzh.jpg" alt />
            </div>
          </div>
          <div class="btn" style="width: 1.5rem">关注微信公众号</div>
          <!-- <div class="tex">扫码下载</div> -->
        </div>
      </div>
    </div>
    <div class="footer">
      <div style="display: flex">
        <div class="tex">江阴千游网络科技有限公司</div>
        <div class="tex" style="margin-left: 0.69rem">
          <!-- <div>主办单位名称：江阴千游网络科技有限公司</div> -->
          <!-- <div>App备案号：苏ICP备2022030317号-9A</div> -->

          <!-- <div>
          请您合理控制游戏时间，避免沉溺游戏影响生活，注意自我保护，防范网络陷阱
        </div> -->
        </div>
        <a href="https://beian.miit.gov.cn/#/Integrated/index">
          <div class="tex" style="margin-left: 0.69rem">
            ICP备案号：苏ICP备2022030317号-8
          </div>
        </a>
      </div>
      <div class="tex">
        健康游戏忠告:抵制不良游戏 拒绝盗版游戏 注意自我保护 防受骗上当
        适度游戏益脑 迷游戏伤身 合理安排时间 享受健康生活
      </div>
      <router-link to="yinsi" target="_blank">《隐私政策》</router-link>
    </div>
    <div class="toast" v-if="isToast">即将震撼来袭 敬请期待</div>
  </div>
</template>

<script>
import {  downloadA } from "@/utils/download";
export default {
  data() {
    return {
      isToast: false,
    };
  },
  created() {},
  mounted() {},
  methods: {
    azTo() {
      window.location.href = ANDROID_URL;
    },
    iosTo() {
      downloadA(IOS_URL);
    },
    PCTo() {
      downloadA(PC_URL,'_self')
      // this.isToast = true;
      // setTimeout(() => {
      //   this.isToast = false;
      // }, 2000);
    },
  },
};
</script>

<style scoped lang="scss">
.max-width {
  max-width: 1920px;
  margin: 0 auto;
}
.original {
  // height: 3.125rem /* 600/192 */;
  position: relative;
  z-index: 2;
  background-color: #58544d;
}
a {
  color: #f8f3e9;
}
.downloadGame {
  width: 100%;
  // height: 3.125rem /* 600/192 */;
  background: url(../../assets/image/pc/bg/146.jpg) no-repeat;
  // background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  box-sizing: border-box;
  position: relative;

  .downloadGame_title {
    height: 0.9375rem /* 180/192 */;
    width: 100%;
    text-align: center;
    line-height: 0.9375rem;
    color: #f8f3e9;
    font-weight: bold;
    font-size: 0.208333rem /* 40/192 */;
  }
  .content {
    display: flex;
    align-items: center;
    box-sizing: border-box;
    padding-bottom: 0.30625rem /* 123/192 */;
    .xz_content {
      display: flex;
      align-items: center;
      flex-direction: column;
      box-sizing: border-box;
      margin-right: 0.32rem;
      .xz_img {
        width: 1.041667rem /* 200/192 */;
        height: 1.041667rem /* 200/192 */;
        background: #3a2d24;
        border-radius: 0.052083rem /* 10/192 */;
        display: flex;
        align-items: center;
        justify-content: center;
        opacity: 1;
        .xia_img {
          width: 0.458333rem /* 88/192 */;
          height: 0.5625rem /* 108/192 */;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
      .btn {
        width: 1.25rem /* 240/192 */;
        height: 0.328125rem /* 63/192 */;
        background: #cc5341;
        border-radius: 0.166667rem /* 32/192 */;
        opacity: 1;
        font-size: 0.1rem /* 30/192 */;
        color: #231c0e;
        margin-top: 34px;
        margin-bottom: 0.104167rem /* 20/192 */;
        text-align: center;
        line-height: 0.328125rem;
        cursor: pointer;
      }
      .tex {
        font-size: 0.125rem /* 24/192 */;
        color: #f8f3e9;
        margin-bottom: 0.411458rem /* 79/192 */;
      }
    }
  }
}
.toast {
  padding: 0.078125rem /* 15/192 */ 0.15625rem /* 30/192 */;
  font-size: 0.09375rem /* 18/192 */;
  background: rgba(0, 0, 0, 0.7);
  text-align: center;
  border-radius: 0.09375rem /* 18/192 */;
  color: #ffffff;
  position: absolute;
  top: 0%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 99999;
}
.footer {
  width: 100%;
  height: 1.151042rem /* 221/192 */;
  background: #231c0e;
  color: #ffffff;
  font-size: 0.083333rem /* 16/192 */;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  line-height: 0.251042rem;
}
</style>
